import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/index.css";
import IconDaidee from "../images/icons/v2/daideeplan.svg";
import IconFYXD from "../images/icons/v2/fyxdfactsheet.svg";
import IconTerm from "../images/icons/v2/tc.svg";
import LogoFyxdInfo from "../images/logos/fyxd-info-color.svg";
import IconEye from "../images/icons/v4/document/eye-24.svg";
import { Navbar } from "./components/navbar";

export default function InfoPage() {
  return (
    <>
      <div style={{ width: "100vw", height: "110vh" }}>
        <Container className="text-center mt-4">
          <a href="#/">
            <h1 className="float-left">{`<`}</h1>
          </a>
          <h2 className="heading--main" style={{ fontWeight: "300" }}>
            Information and FAQs
          </h2>

          <div className="mt-5">
            <div className="document__detail shadow-md">
              <div className="info__detail-content p-1">
                <span className="child">
                  <b>Dai Perm Terms and Conditions</b>
                </span>
                <br />
                <span className="child text-xs">
                  เงื่อนไขการใช้งาน Dai Perm
                </span>
                <br />
              </div>
              <div>
                <a
                  href="https://fyxd-dlc.obs.ap-southeast-2.myhuaweicloud.com/info/DaiPerm_T%26C_%2Bfor%2Bplatform%2Busage_07062024_clean%2Bversion.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="inline w-[80px] pl-2" src={IconEye} alt="" />
                </a>
              </div>
            </div>
            <div className="document__detail mt-3 shadow-md">
              <div className="info__detail-content p-1">
                <span className="child">
                  <b>DaiDee 2.0 Terms and Conditions</b>
                </span>
                <br />
                <span className="child text-xs">เงื่อนไขการใช้งาน Daidee</span>
                <br />
              </div>
              <div>
                <a
                  href="https://fyxd-dlc.obs.ap-southeast-2.myhuaweicloud.com/info/TermsConditions_DaiDee2_Plan_09012024_clean.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="inline w-[80px] pl-2" src={IconEye} alt="" />
                </a>
              </div>
            </div>
            <div className="document__detail mt-3 shadow-md">
              <div className="info__detail-content p-1">
                <span className="child">
                  <b>FYX-D Factsheet</b>
                </span>
                <br />
                <span className="child text-xs">ถามตอบเกี่ยวกับ FYX-D</span>
              </div>
              <a
                href="https://fyxd-dlc.obs.ap-southeast-2.myhuaweicloud.com/info/FAQ_TH_EN_asof_28Aug2023.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <img className="inline w-[80px] pl-2" src={IconEye} alt="" />
              </a>
            </div>
            <div className="document__detail mt-3 shadow-md mb-4">
              <div className="info__detail-content p-1">
                <span className="child">
                  <b>Terms & Conditions</b>
                </span>
                <br />
                <span className="child text-xs">ข้อกำหนดและเงื่อนไขทั่วไป</span>
              </div>
              <a
                href="https://fyxd-dlc.obs.ap-southeast-2.myhuaweicloud.com/info/FYX-D%20TC.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <img className="inline w-[80px] pl-2" src={IconEye} alt="" />
              </a>
            </div>
            <div className="pt-2 mx-auto text-ggrey-400 "> App Version 1.2</div>
            <img
              className="info-dtgo-logo mt-3 w-[55%]"
              alt=""
              src={LogoFyxdInfo}
              style={{ display: "inline-block" }}
            />
          </div>
        </Container>
        {/* <Navbar path="/info" /> */}
      </div>
    </>
  );
}
