import React, { useState, useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/index.css";
import axios from "axios";
import clsx from "clsx";
import LogoFyxd from "../images/logos/fyxd-logo-color.svg";
import IconDaideeInterest from "../images/icons/history-transaction-topup.svg";
import LogoStar from "../images/icons/v4/document/star.svg";
import IconExercisePlan from "../images/icons/v3/exercise-plan-icon.svg";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import IconPortfolioNav from "../images/icons/v3/portfolio-header.svg";
import {
  MsalAuthenticationTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

// Sample app imports
import Error from "./error-page";
import { useNavigate, useParams } from "react-router-dom";

export default function PlanDetailPage() {
  let { id } = useParams();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const baseURI = process.env.REACT_APP_BASE_URI;

  const [accessToken, setAccessToken] = useState("");
  const [recieveAmount, setreceieveAmount] = useState(0);
  const [guaranteedReceivableAmount, setguaranteedReceivableAmount] =
    useState(0);
  const [bonusAmount, setbonusAmount] = useState(0);
  const [planName, setplanName] = useState("");
  const [exerciseDate, setplanExerciseDate] = useState(new Date());
  const [bonusCycle, setbonusCycle] = useState([]);
  const [exercise, setexercise] = useState([]);

  let userName = "",
    Email = "";

  const name = accounts[0] && accounts[0].name;
  const email = accounts[0] && accounts[0].username;

  if (email !== undefined) {
    userName = name;
    Email = email;
  }

  const requestAccessToken = async () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setAccessToken(response.idToken);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const requestToken = async () => {
    await requestAccessToken();
  };

  const getSubscribedPlan = async () => {
    try {
      const res = await axios.post(
        `${baseURI}/famzmember3/v1/fyxd/portfolios/detail`,
        { plan_subscribe_id: id },
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      // console.log(res.data);
      const { data } = res.data;
      setreceieveAmount(parseFloat(data.plan_subscribe.total_amount));
      setguaranteedReceivableAmount(parseFloat(data.guranteed_returns));
      const _bonus =
        parseFloat(data.guranteed_returns) -
        parseFloat(data.plan_subscribe.total_amount);
      setbonusAmount(_bonus);
      setplanName(data.plan.title);
      setplanExerciseDate(data.plan.exercised_date);
      setbonusCycle(data.plan_bonus_cycle);
      setexercise(data.plan_exerise);
      // console.log(data.plan_bonus_cycle)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (Email.length > 0 && accessToken.length > 0 && id.length > 0) {
      getSubscribedPlan();
    }
    if (accessToken.length === 0) requestToken();
    // eslint-disable-next-line
  }, [Email, accessToken, isAuthenticated, id]);

  const authRequest = {
    ...loginRequest,
  };

  const formatDate = (date) => {
    let day = date.getDate().toString().padStart(2, "0");
    let month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    let year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  // Function to format the text
  const formatText = (text) => {
    const regex = /(\d+)(st|nd|rd|th)/g;
    const parts = text.split(regex);

    return parts.map((part, index) => {
      if (index % 3 === 1) {
        // Number part
        return <React.Fragment key={index}>{part}</React.Fragment>;
      } else if (index % 3 === 2) {
        // Superscript part
        return <sup key={index}>{part}</sup>;
      } else {
        // Other parts
        return <React.Fragment key={index}>{part}</React.Fragment>;
      }
    });
  };

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={Error}
      >
        <div className="min-h-screen h-full w-full">
          <nav className="w-full">
            <div className="text-center px-2 py-1 mt-2">
              <img
                src={IconPortfolioNav}
                alt="Logo"
                style={{ marginLeft: "0.5rem", display: "inline-block" }}
                className="mx-auto w-[298px] h-[38px]"
              />
            </div>
          </nav>
          <Tabs style={{ width: "100%" }}>
            <TabList style={{ width: "100%" }}>
              <Tab onClick={() => navigate("/portfolio/0")}>My Portfolio</Tab>
              <Tab onClick={() => navigate("/portfolio/1")}>Tax Documents</Tab>
            </TabList>
          </Tabs>
          <Container className="h-fit">
            <div className="text-center py-1 border-ggrey-200">
              <span
                onClick={goBack}
                className="float-left font-semibold text-xl ml-3 cursor-pointer"
              >
                {"<"}
              </span>
              <span className="text-center text-black font-semibold text-xl">
                {planName}
              </span>
            </div>
            <div className="shadow-md mt-1 p-4 rounded-xl h-fit">
              <div className="mb-2">
                <span className="text-gray-400">Bonus Plan</span>

                <br />
                <span className="text-md font-black text-black mb-2">
                  {planName}
                </span>
              </div>

              <div className="flex flex-row  space-x-4 w-full ">
                <div className="shadow-md document-card p-2 rounded-xl w-full">
                  <span className="xs:text-xxs text-xxs text-white font-bold">
                    Receive Amount
                  </span>
                  <br />
                  <span className="font-black xs:text-xl text-2xl text-bbrown-400 ">
                    {recieveAmount.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                  <br />
                  <span className="text-xs text-white">FYX-D</span>
                </div>
                <div className="shadow-md  document-card  p-2 rounded-xl w-full">
                  <span
                    style={{ paddingBottom: "10px" }}
                    className="xs:text-xxs text-xxs font-bold  text-white"
                  >
                    Guaranteed Bonus*
                    <img
                      style={{ transform: "translateY(-40%)" }}
                      className="inline w-2 float-right"
                      src={LogoStar}
                      alt=""
                    />
                  </span>
                  <br />
                  <span className="font-black xs:text-xl text-2xl  text-bbrown-400">
                    {bonusAmount.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                  <br />
                  <span className="text-xs  text-white">FYX-D</span>
                </div>
              </div>
              <div className="mt-2">
                <span className=" text-xs text-ggrey-300 font-bold">
                  * final receive amount subject to terms and conditions and tax
                  deduction
                </span>
              </div>
            </div>
            <div className="mt-3 bg-white p-4 rounded-xl h-fit shadow-md">
              <div>
                <span className="text-black font-black">Bonus Cycle</span>
              </div>
              <div className="mt-1">
                <span className="text-xs">
                  <b>Remark:</b> All store up value will be convert to FYX-D
                  Bonus at the end of exercise date.
                </span>
              </div>
              <div className="mt-2 mb-2">
                <hr />
                {bonusCycle.map((item, index) => (
                  <>
                    <div key={index} className="mt-3 mb-3">
                      <div>
                        <img
                          className="inline-block align-top"
                          src={IconDaideeInterest}
                        />
                        <div className="inline-block ml-2 w-3/4">
                          <span className="text-sm">
                            {planName}: {formatText(item.plan_detail)}
                          </span>
                          <br />
                          <div>
                            <span className="inline-block text-sm text-green-500">
                              +
                              {item.bonus.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}{" "}
                              FYX-D
                            </span>
                            <div className="inline-block float-right">
                              <span className="text-xs font-bold">
                                on {formatDate(new Date(item.created_at))}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </>
                ))}

                <div className="mt-3 mb-3">
                  <div>
                    <span className="text-sm">
                      <b>Exercise:</b> Convert {planName} {`>`} FYX-D Bonus
                    </span>
                    {exercise.map((item, index) => (
                      <>
                        <div className="mt-2 mb-2">
                          <img
                            className="inline-block align-top"
                            src={IconExercisePlan}
                          />
                          <div className="inline-block ml-2 w-3/4">
                            <span className="text-sm">
                              {planName} Conversion: {item.type}
                            </span>
                            <br />
                            <div>
                              <span className="inline-block text-sm text-green-500">
                                +
                                {item.total_amount.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}{" "}
                                FYX-D
                              </span>
                              <div className="inline-block float-right">
                                <span className="text-xs font-bold">
                                  on {formatDate(new Date(item.created_at))}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </>
                    ))}
                    {exercise.length === 0 && (
                      <div className="flex items-center justify-center mt-3">
                        <span className="text-sm text-center font-bold">
                          No exercise plan
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ height: "3vh" }}></div>
          </Container>
        </div>
      </MsalAuthenticationTemplate>
    </>
  );
}
