import { useState, useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/index.css";
import axios from "axios";
import IconPortfolioNav from "../images/icons/v3/portfolio-header.svg";
import IconTaxNav from "../images/icons/v3/tax-nav-icon.svg";
import IconInfo from "../images/icons/v3/info-icon.svg";
import LogoDaideeBlack from "../images/icons/v3/daidee-black-logo.svg";
import IconPortfolio from "../images/icons/v3/portfolio-black-icon.svg";
import IconGraph from "../images/logos/portfolio_graph.svg";
import IconTotalFyxDUn from "../images/icons/v3/total_fyxd_unrealized.svg";
import IconDaideeList from "../images/icons/v3/daidee-plan-icon.svg";
import { Tooltip } from "react-tooltip";
import IconDaidee from "../images/icons/v2/daideeplan.svg";
import IconEye from "../images/icons/v4/document/eye-24.svg";
import IconDownload from "../images/icons/v4/document/download.svg";
import LogoDColor from "../images/icons/v4/home/icon-38.svg";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import {
  MsalAuthenticationTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import PdfViewer from "./pdfviewer";

// Sample app imports
import Error from "./error-page";
import { Navbar } from "./components/navbar";

import { useNavigate, useParams } from "react-router-dom";
import LogoDaidee2 from "../images/logos/daidee-2.svg";
import toast from "react-hot-toast";

export default function PortfolioPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const goBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const baseURI = process.env.REACT_APP_BASE_URI;
  const [accessToken, setAccessToken] = useState("");
  const [realizedBalance, setrealizedBalance] = useState(0);
  const [unrealizedBalance, setunrealizedBalance] = useState(0);
  const [totalSubscribePlan, settotalSubscribePlan] = useState(0);
  const [plans, setplans] = useState([]);
  const [taxDocuments, settaxDocuments] = useState([]);
  const [pdfUrl, setPdfUrl] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  let userName = "",
    Email = "";

  const name = accounts[0] && accounts[0].name;
  const email = accounts[0] && accounts[0].username;

  if (email !== undefined) {
    userName = name;
    Email = email;
  }

  const requestAccessToken = async () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setAccessToken(response.idToken);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const requestToken = async () => {
    await requestAccessToken();
  };

  const handleOpenInvest = () => {
    navigate("/investment");
  };

  const handleOpenSubscribePlan = (plan_subscribe_id) => {
    navigate(`/plandetail/${plan_subscribe_id}`);
  };

  const getUserPotfolio = async () => {
    console.log("getUserPotfolio");

    try {
      const res = await axios.post(
        `${baseURI}/famzmember3/v1/fyxd/portfolios`,
        {},
        { headers: { Authorization: "Bearer " + accessToken } }
      );

      let sum = 0;
      for (const plan of res.data.data.plan_subscribe) {
        sum += parseFloat(plan.total_amount);
      }

      const total =
        res.data.data.member.fyxd +
        res.data.data.member.bonus_fyxd +
        res.data.data.member.airdrop_fyxd;
      setrealizedBalance(total);
      settotalSubscribePlan(sum);
      setunrealizedBalance(total + sum);
      setplans(res.data.data.plan_subscribe);
    } catch (error) {
      console.error(error);
      requestToken();
    }
  };

  const getDocuments = async () => {
    // /famzmember3/v1/fyxd/user/documents
    console.log("getDocuments");

    try {
      const res = await axios.get(
        `${baseURI}/famzmember3/v1/fyxd/user/documents`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );

      console.log(res.data);
      console.log(res.data.data.documents);
      settaxDocuments(res.data.data.documents);
    } catch (error) {
      console.error(error);
      requestToken();
    }
  };

  useEffect(() => {
    if (Email.length > 0 && accessToken.length > 0) {
      // getUserProfile();
      getUserPotfolio();
      getDocuments();
    }
    if (accessToken.length === 0) requestToken();
  }, [Email, accessToken, isAuthenticated]);

  useEffect(() => {
    if (id) {
      setActiveTab(parseInt(id));
    }
  }, [id]);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const sendMail = async (document_id) => {
    const t = toast.loading("Sending email...");
    try {
      const res = await axios.post(
        `${baseURI}/famzmember3/v1/fyxd/email/sendtaxdocument`,
        { email, document_id },
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      toast.success("Please check your email for the download link.");
    } catch (error) {
      console.error(error);
      requestToken();
    } finally {
      toast.dismiss(t);
    }
  };

  useEffect(() => {
    if (Email.length > 0 && accessToken.length > 0) {
      // getUserProfile();
      getUserPotfolio();
      getDocuments();
    }
    if (accessToken.length === 0) requestToken();
    // eslint-disable-next-line
  }, [Email, accessToken, isAuthenticated]);

  const authRequest = {
    ...loginRequest,
  };

  const tabs = [
    { id: "portfolio", title: "My Portfolio" },
    { id: "plans", title: "Get More Plans" },
    { id: "tax", title: "Tax Documents" },
  ];

  const currentDate = new Date();

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={Error}
      >
        <div style={{ width: "100vw", height: "110vh" }}>
          {pdfUrl === "" && (
            <div style={{ height: "70vh" }}>
              <nav className="w-full">
                <div className="text-center px-2 py-1 mt-2">
                  <img
                    src={IconPortfolioNav}
                    alt="Logo"
                    style={{ marginLeft: "0.5rem", display: "inline-block" }}
                    className="mx-auto w-[298px] h-[38px]"
                  />
                </div>
              </nav>
              <div>
                <Tabs
                  style={{ width: "100%" }}
                  selectedIndex={activeTab} // Bind the selected tab index to the state
                  onSelect={(index) => handleTabClick(index)}
                >
                  <TabList style={{ width: "100%" }}>
                    <Tab>My Portfolio</Tab>
                    <Tab>Tax Documents</Tab>
                  </TabList>
                  <div className="px-3">
                    <TabPanel>
                      <div className="mt-3">
                        <span className="text-xl font-black">My Plans</span>
                        <br />
                        <div
                          style={{
                            borderRadius: "22px",
                            padding: "20px",
                          }}
                          className="mt-2 shadow-md document-card "
                        >
                          <div className="inline-block  flex justify-between ">
                            <span
                              style={{ transform: "translateY(15%)" }}
                              className="xs:text-xxs text-xs inline-block font-black text-bbrown-400"
                            >
                              Total Plans value (Unrealized)
                            </span>
                            <span className="text-black xs:text-xxs text-sm inline-block font-black ">
                              <span className="text-black xs:text-sm text-md font-black inline-block ml-1 ">
                                {totalSubscribePlan.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </span>
                              <img
                                className="my-auto inline ml-1"
                                alt=""
                                src={LogoDColor}
                                style={{
                                  width: "3vh",
                                  transform: "translateY(-10%)",
                                  color: "#4E3721",
                                }}
                              />
                            </span>
                            {/* <a
                              data-tooltip-id="totalbalance3-tooltip"
                              data-tooltip-html="This is the total amount of FYX-D locked up in all of the plans you are participating in."
                            >
                              <img
                                src={IconInfo}
                                alt=""
                                className="inline-block ml-1"
                              />
                            </a>
                            <Tooltip
                              id="totalbalance3-tooltip"
                              style={{ fontSize: "0.5rem" }}
                            /> */}
                          </div>
                        </div>
                        <hr className="mt-3 border-brown-500 border-2" />
                        <div className="mt-3  ">
                          {plans.map((plan) => (
                            <div
                              key={plan.id}
                              style={{
                                borderRadius: "22px",
                                padding: "12px",
                              }}
                              className="cursor-pointer mx-auto mb-3 flex justify-between items-center w-full  shadow-md hover:shadow-lg "
                              onClick={() => handleOpenSubscribePlan(plan.id)}
                            >
                              <div className="mt-2 ">
                                <div
                                  style={{ transform: "translateY(-30%)" }}
                                  className="mb-2 ml-2 font-black text-sm title__portfolio_plan  "
                                >
                                  {plan.title}
                                </div>

                                <div className="inline-block  text-xs ml-2">
                                  Total Value {"  "}
                                  <span className="ml-1 text-sm font-bold">
                                    {"  "}
                                    {parseFloat(
                                      plan.total_amount
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                    <img
                                      className="my-auto inline ml-1"
                                      alt=""
                                      src={LogoDColor}
                                      style={{
                                        width: "3vh",
                                        transform: "translateY(-10%)",
                                        color: "#4E3721",
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                              <div className="flex items-end justify-items-cente ">
                                <div className="mb-2">
                                  <div className="flex  text-sm font-bold  button-percent_plan px-3">
                                    <div>
                                      <img
                                        src={IconGraph}
                                        alt=""
                                        className="inline-block mr-2"
                                        style={{
                                          width: "3vh",
                                        }}
                                      />
                                    </div>
                                    <div
                                      style={{ transform: "translateY(13%)" }}
                                      className=""
                                    >
                                      {" "}
                                      {(
                                        (parseFloat(plan.total_amount) /
                                          totalSubscribePlan) *
                                        100
                                      ).toFixed(2)}
                                      %
                                    </div>
                                  </div>
                                  <div className="text-sm mt-2 font-black text-yyellow-500 float-right ">
                                    {" "}
                                    View more {">>"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        {plans.length === 0 && (
                          <>
                            <div className="flex items-center justify-center">
                              <span className="font-bold text-sm text-gray-400">
                                No Subscribed Plan
                              </span>
                            </div>
                          </>
                        )}
                        <div>
                          <span className="mt-1 text-xs text-ggrey-300 font-bold">
                            Remark: Gain are unrealized value, estimation are
                            rounded up value.
                          </span>
                        </div>

                        <div style={{ height: "15vh" }}></div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <h3 className="mt-3 text-xl font-black ">
                        Tax Documents
                      </h3>
                      {taxDocuments.map((document) => (
                        <div key={document.id} className="mt-3">
                          <div className="mb-3 document__detail shadow-md ">
                            <div className="ml-3 info__detail-content">
                              <span className="child text-xs">
                                <b>{document.name}</b>
                              </span>
                              <br />
                              <span className="child text-xs">
                                <b>{document.description}</b>
                              </span>
                            </div>
                            <div className="flex">
                              {/* <a href={document.document_uri} target="_blank" rel="noreferrer"> */}
                              <button
                                onClick={() => setPdfUrl(document.document_uri)}
                                className="inline-block"
                              >
                                <img
                                  className="inline w-[97px] pr-2"
                                  src={IconEye}
                                  alt=""
                                />
                              </button>
                              <button
                                onClick={() => sendMail(document.id)}
                                className="inline-block w-[85px]"
                              >
                                <img src={IconDownload} alt="" />
                              </button>
                              {/* </a> */}
                            </div>
                          </div>
                        </div>
                      ))}
                    </TabPanel>
                  </div>
                </Tabs>
              </div>
            </div>
          )}
          {pdfUrl !== "" && (
            <>
              <div className="w-full bg-yyellow-700 p-2">
                <button
                  onClick={() => setPdfUrl("")}
                  className="text-white px-2 py-1"
                >
                  Back
                </button>
              </div>
              <PdfViewer pdfUrl={pdfUrl} />
            </>
          )}
          {/* Navbar */}
          <Navbar path="/portfolio" />
        </div>
      </MsalAuthenticationTemplate>
    </>
  );
}
